export const environment = {
  production: false,
  apiUrl: 'https://gereturns-api-dev.cc.pitneycloud.com',
  loginPageUrl: 'https://login2-stg.saase2e.pitneycloud.com/login?productId=geapp',
  logoutPageUrl: 'https://login2-stg.saase2e.pitneycloud.com/logout',
  okta: {
    issuer: 'https://pitneybowes.oktapreview.com/oauth2/ausm5kx49oZfUv3ti0h7',
    clientId: '0oa1ceir6868eM5eB0h8',
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['geadmin', 'openid', 'profile', 'email'],
  },
};
